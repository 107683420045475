import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        path: '/relation',
        component: () => import(/* webpackChunkName: "relation_view" */ '@/views/relation-view/view.vue'),
        children: [
            {
                name: 'relation_view_general',
                path: '',
                component: () => import(/* webpackChunkName: "relation_view_general" */ '@/views/relation-view/tabs/general.vue'),
                meta: { title: 'Customer details' },
            },
            {
                name: 'relation_view_people',
                path: 'people',
                component: () => import(/* webpackChunkName: "relation_view_people" */ '@/views/relation-view/tabs/people.vue'),
                meta: { title: 'Customer details' },
            },
            {
                name: 'relation_view_organization',
                path: 'organization',
                component: () => import(/* webpackChunkName: "relation_view_organization" */ '@/views/relation-view/tabs/organization.vue'),
                meta: { title: 'Customer details' },
            },
        ],
    },
]
export default routes;
