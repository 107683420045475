<template>
    <div>
        <header>
            <nav class="navbar navbar-expand-lg bg-white flex-wrap">
                <div class="container-xxl flex-wrap">
                    <div class="col-12 col-lg-auto mainmenu d-flex justify-content-between">
                        <router-link to="/" class="navbar-brand">
                            <img src="/logo-dark.png" class="logo" alt="Postma &amp; Cohen" />
                        </router-link>

                        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#poco-main-menu">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div id="poco-main-menu" class="offcanvas offcanvas-end" ref="mainMenuOffcanvas">
                            <div class="offcanvas-header">
                                <h5 class="offcanvas-title">Menu</h5>
                                <button class="btn-close" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div class="offcanvas-body">
                                <ul v-if="relationMenu" class="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li class="nav-item dropdown" v-for="item in relationMenu" :key="item.key" >
                                        <template v-if="item.link">
                                            <router-link :to="item.link" class="nav-link text-nowrap">{{ $t(item.title) }}</router-link>
                                        </template>
                                        <template v-else-if="item.items">
                                            <a class="nav-link text-nowrap dropdown-toggle" href="javascript:" @click="toggleSubmenu(item)" :id="'navbarDropdown_' + item.key" role="button" :aria-expanded="item.dropdown ? 'true' : 'false'">
                                                {{ $t(item.title) }}
                                            </a>
                                            <ul v-if="item.items" class="dropdown-menu" :class="{ 'show': item.dropdown }" :aria-labelledby="'navbarDropdown_' + item.key">
                                                <li v-for="subItem in item.items" :key="subItem.key">
                                                    <router-link :to="subItem.link" class="dropdown-item" @click.native="closeMainMenu">{{ $t(subItem.title) }}</router-link>
                                                </li>
                                            </ul>
                                        </template>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-auto d-flex align-items-center justify-content-end">
                        <div class="text-end" style="color:#878787;">
                            <span class="welcome-text">{{ $t('Welcome') }}</span><br />
                            <span class="fw-bold">{{ $store.state.user.name }}</span>
                        </div>
                        <div class="dropdown">
                            <button @click="toggleUserMenu()" class="btn btn-sm btn-nav-icon btn-secondary ms-3" type="button" id="navbarDropdown_user" role="button" :aria-expanded="userDropdown ? 'true' : 'false'" :title="$t('User menu')">
                                <span class="material-symbols-outlined lg">person</span>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end" :class="{ 'show': userDropdown }" aria-labelledby="navbarDropdown_user">
                                <li>
                                    <a href="javascript:" class="dropdown-item disabled text-end">Postma &amp; Cohen {{ pcVersion }}</a>
                                </li>
                                <li><hr class="dropdown-divider"></li>
                                <li v-if="$store.state.manualExists && !$store.state.user.roles.ROLE_OCI_USER">
                                    <a href="javascript:" @click="downloadManual()" class="dropdown-item text-end">{{ $t('Download manual (PDF)') }}</a>
                                </li>
                                <li>
                                    <a href="javascript:" @click="toggleLanguage()" class="dropdown-item text-end">{{ toggleLanguageText }}</a>
                                </li>
                                <li><hr class="dropdown-divider"></li>
                                <li>
                                    <router-link :to="{ name: 'my_settings_password' }" class="dropdown-item text-end" v-if="!$store.state.user.roles.ROLE_OCI_USER">{{ $t('My settings') }}</router-link>
                                </li>
                                <li v-if="$store.state.user.isSwitched && !$store.state.user.roles.ROLE_OCI_USER">
                                    <a href="javascript:" @click="openManualDialog()" class="dropdown-item text-end">{{ $t('Edit manual') }}</a>
                                </li>
                                <li v-if="$store.state.user.isSwitched">
                                    <a href="javascript:" @click="$toPrevious()" class="dropdown-item text-end">{{ $t('Back to previous user') }}</a>
                                </li>
                                <li>
                                    <a href="javascript:" @click="$logout()" class="dropdown-item text-end">{{ $t('Logout') }}</a>
                                </li>
                            </ul>
                        </div>
                        <button class="btn btn-sm btn-nav-icon btn-clean position-relative ms-3" type="button" @click="$router.push({ name: 'cart_index' })" :title="$t('Cart')">
                            <span class="material-symbols-outlined lg text-secondary">shopping_cart</span>
                            <span v-if="cartTotal > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                {{ cartTotal }}
                            </span>
                        </button>
                        <button class="btn btn-sm btn-nav-icon btn-clean position-relative ms-3" type="button" @click="openNewsOffcanvas()" :title="$t('News')">
                            <span class="material-symbols-outlined lg text-secondary">newspaper</span>
                            <span v-if="newsTotal > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                {{ newsTotal }}
                            </span>
                        </button>
                        <span v-if="!hideContact">
                            <button class="btn btn-sm btn-nav-icon btn-clean ms-3" type="button" @click="$router.push({ name: 'contact_page' })" :title="$t('Contact information')">
                                <span class="material-symbols-outlined lg text-secondary">call</span>
                            </button>
                        </span>
                    </div>
                </div>
            </nav>
        </header>
        <main role="main" class="container-xxl">
            <h1 v-if="$route.meta && $route.meta.title" class="page-title">{{ $t($route.meta.title) }}</h1>

            <div class="alert mt-4 alert-dismissible fade show alert-fixed"
                 role="alert"
                 v-bind="$store.state.flashMessageOptions"
                 v-if="$store.getters.hasFlash"
            >
                {{ $store.state.flashMessage }}
                <button type="button" class="btn-close" @click="$store.commit('flashMessage', '')" aria-label="Sluiten"></button>
            </div>

            <slot />
        </main>
        <pc-btn v-if="manual.available" class="btn-sm btn-nav-icon btn-secondary btn-fixed-bottom-left" @click="manual.enabled = true">
            <span class="material-symbols-outlined lg">help</span>
        </pc-btn>
        <pc-modal v-model="manual.enabled">
            <template v-slot:label>{{ manual.title }}</template>
            <template v-slot:body>
                <div v-html="manual.content"></div>
            </template>
        </pc-modal>
        <pc-offcanvas v-model="newsOffcanvas.enabled" @disabled="setNewsRead">
            <template v-slot:label>{{ $t('News') }}</template>
            <template v-slot:body>
                <template v-if="!newsOffcanvas.items.length">
                    <div class="alert alert-info">{{ $t('No news to show') }}</div>
                </template>
                <template v-for="(newsItem, i) in newsOffcanvas.items">
                    <div class="memo mb-4" :key="i">
                        <h5 class="tab-title">{{ newsItem.title }}</h5>
                        <div v-html="newsItem.content"></div>
                    </div>
                </template>
                <pc-btn class="btn-sm btn-outline-primary" @click="() => { newsOffcanvas.enabled = false; $router.push({ name: 'news_list' }); }">{{ $t('News archive') }}</pc-btn>
            </template>
        </pc-offcanvas>
        <manual-dialog />
    </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import { Request } from "@/lib/apollo";
import ManualDialog from "@/components/manual/ManualDialog.vue";
import {Offcanvas} from "bootstrap";

export let RelationMenu: Array<any> = [
    {
        key: 'product',
        title: 'My Products',
        items: [
            { key: 'product-totaloverview', title: 'Total overview', link: { name: 'orders_all' } },
            { key: 'product-subscriptions', title: 'Subscriptions', link: { name: 'orders_subscription' } },
            { key: 'product-books', title: 'Books', link: { name: 'orders_book' } },
            { key: 'product-memberships', title: 'Memberships', link: { name: 'orders_membership' } },
        ],
        dropdown: false,
    },
    {
        key: 'order',
        title: 'Order now',
        items: [
            { key: 'order-subscriptions', title: 'Subscriptions', link: { name: 'catalog_subscription' } },
            { key: 'order-books', title: 'Books', link: { name: 'catalog_book' } },
            { key: 'order-new', title: 'New books', link: { name: 'catalog_new' } },
        ],
        dropdown: false,
    },
    {
        key: 'finance',
        title: 'Financial',
        items: [
            { key: 'finance-management', title: 'Management information', link: { name: 'management_info_intro' } },
            { key: 'finance-budgetting', title: 'Budgeting', link: { name: 'budgeting_overview' } },
            { key: 'finance-overview', title: 'Invoice overview', link: { name: 'invoice_list' } },
        ],
        dropdown: false,
    },
    {
        key: 'misc',
        title: 'Miscellaneous',
        items: [
            { key: 'misc-packagingslip', title: 'Packing Slips', link: { name: 'packing_slip_list' } },
            { key: 'misc-communication', title: 'Communication', link: { name: 'contact_list' } },
            { key: 'misc-ordertemplates', title: 'Addresses', link: { name: 'address_list' } },
            { key: 'misc-readerlist', title: 'Reader list', link: { name: 'reader_list' } },
            { key: 'misc-circulation', title: 'Circulation lists', link: { name: 'circulation_list_overview' } },
        ],
        dropdown: false,
    },
    {
        key: 'customer-details',
        title: 'Customer details',
        link: { name: 'relation_view_general' }
    },
];

@Component({
    components: {
        ManualDialog,
    },
})
export default class DefaultLayout extends Vue {
    cartTotal = 0;

    newsTotal = 0;
    newsOffcanvas: any = {
        enabled: false,
        items: [],
    };

    userDropdown = false;

    relationMenu: any = {};

    manual: any = {
        available: false,
        enabled: false,
        title: '',
        content: '',
        route: '',
    };

    hideContact = false;

    mounted() {
        this.relationMenu = this.createMenu();

        this.$root.$off('refresh-cart-count');
        this.$root.$on('refresh-cart-count', () => {
            Request.make('/api/cart/total', Request.GET).then((res: any) => {
                this.cartTotal = res.total;
            });
        });
        this.$root.$emit('refresh-cart-count');

        this.$root.$off('refresh-news-count');
        this.$root.$on('refresh-news-count', () => {
            Request.make('/news/unseen/count', Request.GET).then((res: any) => {
                this.newsTotal = res.total;
            });
        });
        this.$root.$emit('refresh-news-count');

        Request.make('/manual/exists').then((res: any) => {
            if (res) {
                this.$store.commit('manualExists', res.exists);
            }
        });

        document.addEventListener('click', (ev) => {
            const clickTarget = ev.target as HTMLElement;
            if (!clickTarget) {
                return;
            }

            const inDropdown = (target: HTMLElement): boolean => {
                const parent = target.parentNode as HTMLElement;
                if (target.classList.contains('dropdown-item')) {
                    return false;
                } else if (target === document.body) {
                    return false;
                } else if (target.classList.contains('dropdown')) {
                    return true;
                } else if (parent) {
                    return inDropdown(parent);
                }
                return false;
            };
            if (inDropdown(clickTarget)) {
                return;
            }

            for (let i in this.relationMenu) {
                this.relationMenu[i].dropdown = false;
            }
            this.userDropdown = false;
        });

        this.hideContact = ['14189'].indexOf(this.$store.state.user.relationNumber) >= 0;

        this.onRouteChange(this.$route.name as string);
    }

    createMenu() {
        const authenticated: boolean = this.$store.state.authenticated;
        const roles: any = this.$store.state.user.roles;

        const output: Array<any> = [];

        if (authenticated && (roles.ROLE_RELATION_MAIN_USER || roles.ROLE_RELATION_SUB_USER)) {
            let groupsVisible = this.$store.state.user.groupsVisible;
            let group;
            for (let i in RelationMenu) {
                // Todo: Klantgegevens voor nu maar even altijd tonen
                if (groupsVisible.indexOf('access-' + RelationMenu[i]['key']) > -1 || (RelationMenu[i]['key'] === 'customer-details' && roles.ROLE_RELATION_MAIN_USER)) {
                    group = Object.assign({}, RelationMenu[i]);
                    if (group.items) {
                        group.items = [];
                        for (let j in RelationMenu[i]['items']) {
                            if (groupsVisible.indexOf('access-' + RelationMenu[i]['items'][j]['key']) > -1) {
                                group.items.push(Object.assign({}, RelationMenu[i]['items'][j]));
                            }
                        }
                    }
                    output.push(group);
                }
            }
        }
        return output;
    }

    toggleSubmenu(item: any) {
        for (let i in this.relationMenu) {
            if (this.relationMenu[i].key === item.key) {
                continue;
            }
            this.relationMenu[i].dropdown = false;
        }

        item.dropdown = !item.dropdown;
    }

    toggleUserMenu() {
        this.userDropdown = !this.userDropdown;
    }

    get toggleLanguageText() {
        if (this.$i18n.locale === 'nl') {
            return 'Change language to English';
        }
        return 'Verander taal naar Nederlands';
    }

    get pcVersion() {
        return process.env.VUE_APP_VERSION;
    }

    toggleLanguage() {
        const locale = (this.$i18n.locale === 'nl' ? 'en' : 'nl');
        this.$i18n.locale = locale;
        this.$store.commit('locale', locale);
        this.$root.$emit('change-locale', 'change');

        Request.make('/locale/change/' + locale);
    }

    @Watch('$route.name')
    onRouteChange(route: string) {
        this.manual = {
            available: false,
            enabled: false,
            title: '',
            content: '',
        };

        window.setTimeout(() => {
            const params = {
                name: route,
                lang: this.$store.state.locale,
            };

            Request.make('/manual/gather', Request.GET, params).then((res: any) => {
                if (res.manual && res.manual.id) {
                    this.manual.title = res.manual.title;
                    this.manual.content = res.manual.content;
                    this.manual.available = true;
                    this.manual.route = params['name'];
                }
            });
        }, 1000);
    }

    openNewsOffcanvas() {
        Request.make('/news/unseen/list', Request.GET).then((res: any) => {
            this.newsOffcanvas.items = res.items;
            this.newsOffcanvas.enabled = true;
        });
    }

    setNewsRead() {
        const data = new FormData();

        for (let key in this.newsOffcanvas.items) {
            data.append('id[]', this.newsOffcanvas.items[key].id);
        }

        Request.make('/news/set-seen', Request.POST, data).then((res: any) => {
            this.newsTotal = res.total;
        });
    }

    downloadManual() {
        window.open(process.env['VUE_APP_API_HOST'] + '/manual/download', '_blank');
    }

    openManualDialog() {
        this.$root.$emit('manual-dialog-enable', {
            routeName: this.$router.currentRoute.name,
        });
    }

    closeMainMenu() {
        const elm = this.$refs['mainMenuOffcanvas'] as HTMLDivElement;
        const offcanvas: Offcanvas|null = Offcanvas.getInstance(elm);
        if (offcanvas) {
            offcanvas.hide();
        }
    }
}
</script>

<style lang="scss">
    .welcome-text {
        font-size: 1.14285714286rem;
    }
    .btn-fixed-bottom-left {
        position: fixed;
        bottom: 1.4285714286rem;
        left: 1.4285714286rem;
    }
</style>
