<template>
    <div class="mb-3 form-number" :class="{ 'has-error': hasError }">
        <label class="form-label" v-if="label.length" :for="labelID">{{ $t(label) }}:</label>
        <div class="row row-cols-auto" :class="{ 'is-invalid': hasError }">
            <div class="col pe-0">
                <button type="button" class="btn btn-clean pt-1 pb-0" @click="decrease">
                    <span style="font-variation-settings: 'FILL' 1;" class="material-symbols-outlined xl text-danger">remove_circle</span>
                </button>
            </div>
            <div class="col ps-1 pe-1">
                <input type="text" class="form-control" v-bind="$attrs" :id="labelID" v-model="inputValue">
            </div>
            <div class="col ps-0">
                <button type="button" class="btn btn-clean pt-1 pb-0" @click="increase">
                    <span style="font-variation-settings: 'FILL' 1;" class="material-symbols-outlined xl text-primary">add_circle</span>
                </button>
            </div>
        </div>
        <div class="invalid-feedback" v-if="feedback.length">
            <template v-for="(msg, i) in feedback">
                <span :key="i">{{ msg }}<br /></span>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

@Component({
    inheritAttrs: false,
})
export default class PCNumber extends Vue {
    @Prop({ default: 0 })
    readonly value: number;

    @Prop({ default: '' })
    readonly label: string;

    @Prop({ default: false })
    readonly hasError: boolean;

    @Prop({ default: '' })
    readonly feedback: string;

    @Prop({ default: 'string' })
    readonly min: string;

    inputValue = 0;
    labelID = '';

    mounted () {
        this.inputValue = this.value;
        this.$watch('inputValue', this.onInputChange);
        this.labelID = 'pc-input-' + Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 5)
    }

    @Watch('value')
    onValueChange(val: number) {
        this.inputValue = val;
    }

    onInputChange(val: number) {
        const min = parseInt(this.min);
        if (val < min) {
            this.inputValue = min;
            this.$emit('input', min);
            this.$emit('change', min);
            return;
        }
        this.$emit('input', val);
        this.$emit('change', val);
    }

    decrease() {
        if ((this.inputValue - 1) < 0) {
            this.inputValue = 0;
            return;
        }
        this.inputValue--;
    }

    increase() {
        this.inputValue++;
    }
}
</script>
