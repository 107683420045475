<template>
    <div>
        <h4 class="mb-5">{{ $t('Login') }}</h4>
        <pc-form @submit="performAuth()">
            <pc-input type="text" label="Username" required="required" autofocus v-model="loginForm.username" />
            <pc-input type="password" label="Password" required="required" v-model="loginForm.password" />
            <pc-checkbox label="Remember me" v-model="loginForm.rememberMe" />
            <div class="container-fluid">
                <div class="row align-items-center p-0 pt-4">
                    <div class="col d-grid gap-2 ps-0"><pc-btn type="submit" class="btn-primary">{{ $t('Login') }}</pc-btn></div>
                    <div class="col d-grid gap-2 pe-0"><router-link :to="{ name: 'forgot_password' }" class="btn btn-clean btn-sm">{{ $t('Forgot password') }}</router-link></div>
                </div>
            </div>
        </pc-form>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Login extends Vue {
    loginForm: any = {
        username: '',
        password: '',
        rememberMe: false
    }

    performAuth () {
        this.$login({
            'username': this.loginForm.username,
            'password': this.loginForm.password,
            '_remember_me': this.loginForm.rememberMe,
        });
    }
}
</script>
